<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="formView">
          <v-btn value="0">50/50</v-btn>
          <v-btn value="1">Lomake</v-btn>
          <v-btn value="2">Esikatselu</v-btn>
          <v-btn value="3">Sähköposti</v-btn>
        </v-btn-toggle>
        <v-slider
          v-if="formView == '0'"
          v-model="sliderValue"
          class="ma-0"
          min="2"
          max="10"
          @input="updateColumnWidths"
          hide-details
        ></v-slider>
      </v-col>
    </v-row>

    <v-row v-if="formView != '1' && formView != '3'" dense>
      <v-col cols="12">
        <h4>
          Näkymän asetukset
          <span>
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary"> mdi-information-outline </v-icon>
              </template>
              <div>
                <p>
                  Responsiivisuus määritellään max. 5 eri arvolla välilyönnillä erotellen. Arvot
                  voivat olla välillä 1-12.
                </p>
                <p>
                  Jokainen viidestä eri arvosta kertoo, kuinka paljon tilaa elementti vie 12
                  palstaan jaetussa näkymässä tietyllä näyttökoolla . Ensimmäinen numero on
                  mobiilille, seuraava tabletille jne isommille näytöille. Jos kaikkia viittä
                  numeroa ei määritellä, viimeisin numero pätee myös suurempiin näyttökokoihin.
                </p>
                <p>Esim. '12': Kaikilla näyttökooilla täytetään tila kokonaan.</p>
                <p>
                  Esim. '12 6': Mobiilissa täytetään koko ruutu, tabletilla ja sitä suuremmilla
                  näytöillä puoli ruutua.
                </p>
                <p>
                  Esim. '12 8 6': Mobiilissa täytetään koko ruutu, tabletilla 8/12 ruutua ja
                  lg-kokoisella ja sitä suuremmilla näytöllä puolet.
                </p>
              </div>
            </v-tooltip>
          </span>
        </h4>
      </v-col>
    </v-row>

    <!-- Form name -->
    <v-form v-show="formView != '1' && formView != '3'" ref="form_options">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="2" xl="1"
          ><v-text-field
            v-model="width"
            label="Responsiivisuus"
            outlined
            dense
            :rules="validations.responsive"
            persistent-hint
            hint="Esim. '12 6' tai '12'"
          ></v-text-field
        ></v-col>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1">
          <v-select
            v-model="align"
            outlined
            dense
            :items="[
              { text: 'Vasen', val: 'left' },
              { text: 'Keskellä', val: 'center' },
              { text: 'Oikea', val: 'right' },
            ]"
            item-value="val"
            item-text="text"
            label="Asettelu"
            hide-details
          >
          </v-select
        ></v-col>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1">
          <v-select v-model="fontFamily" outlined dense :items="fonts" label="Fontti" hide-details>
          </v-select
        ></v-col>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1"
          ><v-text-field
            v-model="formPadding"
            label="Lomake padding"
            outlined
            dense
            :rules="validations.colPadding"
            persistent-hint
            hint="Esim. 2 2 2 2"
          ></v-text-field
        ></v-col>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1"
          ><v-checkbox
            v-model="border"
            label="Reunaviiva"
            style="margin-top: 0px; padding-top: 0px"
            hide-details
          ></v-checkbox
        ></v-col>

        <template>
          <form-colors> </form-colors>
        </template>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1"
          ><v-checkbox
            v-model="showError"
            label="Näytä error viestikentässä"
            style="margin-top: 0px; padding-top: 0px"
            hide-details
          ></v-checkbox
        ></v-col>

        <v-col cols="12" sm="6" md="4" lg="2" xl="1"
          ><v-checkbox
            v-model="showNameAbove"
            label="Näytä nimi kentän yläpuolella"
            style="margin-top: 0px; padding-top: 0px"
            hide-details
          ></v-checkbox
        ></v-col>
      </v-row>
    </v-form>

    <v-row v-if="formView != '2' && formView != '3'" class="mt-1" dense>
      <v-col cols="12">
        <h4>Lomake</h4>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- FORM -->
      <v-col
        v-show="formViewOptions.form.show"
        :cols="formView == '0' ? leftColumnWidth : 12"
        :class="formViewOptions.form.show ? 'pr-1' : ''"
      >
        <v-form ref="form_body">
          <v-row v-if="formView != '2'" dense>
            <v-col cols="12" sm="6" md="4" lg="3"
              ><v-text-field
                v-model="name"
                label="Lomakkeen nimi"
                outlined
                dense
                hide-details
                :rules="validations.required"
              ></v-text-field
            ></v-col>

            <v-col cols="12" sm="6" md="4"
              ><v-text-field
                v-model="recipients"
                label="Vastaanottajat"
                outlined
                dense
                placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
                :rules="validateEmails"
                hint="Erota sähköpostit pilkulla ja välilyönnillä"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col v-if="!edit" cols="12" sm="6" md="4" lg="3"
              ><v-select
                label="Lataa lomake"
                :items="['Liitä kopioitu lomake', 'Asuntohakemus']"
                outlined
                dense
                hide-details
                @change="loadTemplate"
              ></v-select
            ></v-col>
          </v-row>

          <small
            >Tunnistenimi on yksilöllinen nimi, jolla kentät nimetään lomakkeen vastaanottajalle.
            Tunnistenimi ei näy lomakkeen täyttäjälle. Anna tunnistenimeksi joku kuvaava nimi, jolla
            tunnistat mitä täytettyä kenttää teksti tai valinta vastaa.</small
          >

          <!-- ROWS -->
          <draggable v-model="rows" handle=".handleRow">
            <v-row
              v-for="(row, rowIndex) in rows"
              :key="rowIndex + 'a'"
              dense
              class="mt-1 row-container"
              align="center"
            >
              <!-- hide show icon -->

              <v-icon
                style="position: absolute; top: 0; right: 0; cursor: pointer"
                @click="hideOrShowRow(rowIndex)"
                >{{ row.showRow ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>

              <!-- No show -->
              <v-col v-show="!row.showRow" cols="12">
                <h4>Rivi {{ rowIndex + 1 }}</h4>
                <small>{{ getLabels(row.cols) }}</small>
              </v-col>

              <!-- COLS -->
              <v-col v-show="row.showRow" cols="12">
                <draggable
                  :value="row.cols"
                  @end="updateCols(rowIndex, $event)"
                  handle=".handleCol"
                >
                  <v-row
                    dense
                    align="center"
                    v-for="(col, colIndex) in row.cols"
                    :key="colIndex + 'b'"
                  >
                    <v-col cols="12" style="padding-bottom: 0px">
                      <v-row dense align="center" class="row-container-2">
                        <!-- Field Type -->
                        <v-col
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <v-select
                            :ref="`select_${rowIndex}_${colIndex}`"
                            :value="col.fieldType"
                            :items="fieldTypes"
                            item-value="val"
                            item-text="text"
                            label="Tyyppi"
                            outlined
                            dense
                            hide-details
                            @change="
                              removeUnnecessaryRules({ rowIndex, colIndex });
                              checkSubmissions({
                                val: $event,
                                colIndex,
                                rowIndex,
                                labelExists: col.label ? true : false,
                                oldFieldType: col.oldFieldType ? col.oldFieldType : null,
                                field: 'fieldType',
                              });
                            "
                            :rules="validations.required"
                          >
                            <template slot="prepend">
                              <v-icon
                                size="15"
                                class="handleCol"
                                style="cursor: move; margin-top: 5px"
                                >mdi-arrow-all</v-icon
                              >
                            </template>
                          </v-select>
                        </v-col>

                        <!-- CENTER FIELDS -->
                        <v-col
                          v-if="
                            col.fieldType === 'h1' ||
                            col.fieldType === 'h2' ||
                            col.fieldType === 'h3' ||
                            col.fieldType === 'p' ||
                            col.fieldType === 'button' ||
                            col.fieldType === 'submit'
                          "
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <v-checkbox
                            style="margin-top: 0px; padding-top: 0px"
                            v-model="col.textCenter"
                            label="Keskitetty"
                            outlined
                            dense
                            hide-details
                            @change="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'textCenter',
                              })
                            "
                          ></v-checkbox>
                        </v-col>

                        <!-- TEXT -->
                        <template
                          v-if="
                            col.fieldType === 'h1' ||
                            col.fieldType === 'h2' ||
                            col.fieldType === 'h3' ||
                            col.fieldType === 'p' ||
                            col.fieldType === 'button' ||
                            col.fieldType === 'submit'
                          "
                        >
                          <v-col
                            cols="12"
                            :sm="sliderValue >= 6 ? 6 : columnWidthsLeft.sm + 1"
                            :md="sliderValue >= 6 ? 6 : columnWidthsLeft.md + 1"
                            :lg="sliderValue >= 6 ? 5 : columnWidthsLeft.lg + 1"
                            :xl="sliderValue >= 6 ? 4 : columnWidthsLeft.xl + 1"
                          >
                            <v-text-field
                              v-if="
                                col.fieldType === 'h1' ||
                                col.fieldType === 'h2' ||
                                col.fieldType === 'h3' ||
                                col.fieldType === 'button' ||
                                col.fieldType === 'submit'
                              "
                              :value="col.text"
                              label="Teksti"
                              outlined
                              dense
                              hide-details
                              @input="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'text',
                                })
                              "
                              :rules="validations.required"
                            ></v-text-field>

                            <v-textarea
                              v-else
                              :value="col.text"
                              label="Teksti"
                              outlined
                              dense
                              hide-details
                              rows="1"
                              @input="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'text',
                                })
                              "
                              :rules="validations.required"
                            ></v-textarea>
                          </v-col>

                          <v-col
                            v-if="col.fieldType === 'button'"
                            cols="12"
                            :sm="sliderValue >= 6 ? 6 : columnWidthsLeft.sm + 1"
                            :md="sliderValue >= 6 ? 6 : columnWidthsLeft.md + 1"
                            :lg="sliderValue >= 6 ? 5 : columnWidthsLeft.lg + 1"
                            :xl="sliderValue >= 6 ? 4 : columnWidthsLeft.xl + 1"
                          >
                            <v-text-field
                              :value="col.link"
                              label="Linkki"
                              placeholder="https://..."
                              outlined
                              dense
                              hide-details
                              @input="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'link',
                                })
                              "
                              :rules="validations.required"
                            ></v-text-field>
                          </v-col>
                        </template>

                        <!-- OTHER FIELDS -->
                        <template
                          v-if="
                            col.fieldType != 'h1' &&
                            col.fieldType != 'h2' &&
                            col.fieldType != 'h3' &&
                            col.fieldType != 'p' &&
                            col.fieldType != 'logo' &&
                            col.fieldType != 'button' &&
                            col.fieldType != 'submit'
                          "
                        >
                          <!-- Name -->
                          <v-col
                            cols="12"
                            :sm="columnWidthsLeft.sm"
                            :md="columnWidthsLeft.md"
                            :lg="columnWidthsLeft.lg"
                            :xl="columnWidthsLeft.xl"
                          >
                            <v-text-field
                              ref="name"
                              :value="col.name"
                              label="Kentän nimi"
                              outlined
                              dense
                              @input="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'name',
                                  type: col.type,
                                  fieldType: col.fieldType,
                                })
                              "
                              hide-details
                              :rules="validations.required"
                            ></v-text-field>
                          </v-col>

                          <!-- Label -->
                          <v-col
                            cols="12"
                            :sm="columnWidthsLeft.sm"
                            :md="columnWidthsLeft.md"
                            :lg="columnWidthsLeft.lg"
                            :xl="columnWidthsLeft.xl"
                          >
                            <v-text-field
                              ref="label"
                              :value="col.label"
                              label="Tunnistenimi"
                              outlined
                              dense
                              @input="
                                renameRules({ previousLabel: col.label, newLabel: $event });
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'label',
                                  type: col.type,
                                  fieldType: col.fieldType,
                                });
                              "
                              hide-details
                              :rules="[
                                ...validations.required,
                                uniqueLabels,
                                noDots,
                                unAvailableLabelsDenied({ rowIndex, colIndex, val: col.label }),
                              ]"
                            ></v-text-field>
                          </v-col>

                          <!-- Field style -->
                          <v-col
                            v-if="
                              col.fieldType != 'checkbox' &&
                              col.fieldType != 'radio' &&
                              col.fieldType != 'logo' &&
                              col.fieldType != 'submit'
                            "
                            cols="12"
                            :sm="columnWidthsLeft.sm"
                            :md="columnWidthsLeft.md"
                            :lg="columnWidthsLeft.lg"
                            :xl="columnWidthsLeft.xl"
                          >
                            <v-select
                              :value="col.fieldStyle"
                              :items="fieldStyles"
                              item-value="val"
                              item-text="text"
                              label="Tyyli"
                              outlined
                              dense
                              hide-details
                              @change="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'fieldStyle',
                                })
                              "
                              :rules="validations.required"
                            ></v-select>
                          </v-col>

                          <!-- Radio / Select / Multiselect -->
                          <v-col
                            v-if="
                              col.fieldType == 'radio' ||
                              col.fieldType == 'select' ||
                              col.fieldType == 'multiselect'
                            "
                            cols="12"
                            :sm="sliderValue >= 6 ? 6 : columnWidthsLeft.sm + 1"
                            :md="sliderValue >= 6 ? 6 : columnWidthsLeft.md + 1"
                            :lg="sliderValue >= 6 ? 5 : columnWidthsLeft.lg + 1"
                            :xl="sliderValue >= 6 ? 4 : columnWidthsLeft.xl + 1"
                          >
                            <!-- Options -->
                            <v-text-field
                              :value="col.options"
                              label="Valinnat"
                              outlined
                              dense
                              hide-details
                              @input="
                                updateCol({
                                  val: $event,
                                  colIndex,
                                  rowIndex,
                                  field: 'options',
                                });
                                removeRuleOptions({ rowIndex, colIndex, val: col.label });
                              "
                              :rules="validations.required"
                            ></v-text-field>
                          </v-col>
                        </template>

                        <!-- Required -->
                        <v-col
                          v-if="
                            col.fieldType != 'h1' &&
                            col.fieldType != 'h2' &&
                            col.fieldType != 'h3' &&
                            col.fieldType != 'p' &&
                            col.fieldType != 'logo' &&
                            col.fieldType != 'button' &&
                            col.fieldType != 'submit'
                          "
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <v-checkbox
                            v-model="col.required"
                            style="margin-top: 0px; padding-top: 0px"
                            label="Pakollinen"
                            outlined
                            dense
                            hide-details
                            @change="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'required',
                              })
                            "
                          ></v-checkbox>
                        </v-col>

                        <!-- Required -->
                        <v-col
                          v-if="col.fieldType == 'image'"
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <v-checkbox
                            v-model="col.multipleImages"
                            style="margin-top: 0px; padding-top: 0px"
                            label="Useita kuvia"
                            outlined
                            dense
                            hide-details
                            @change="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'multipleImages',
                              })
                            "
                          ></v-checkbox>
                        </v-col>

                        <!-- align -->
                        <v-col
                          v-if="col.fieldType == 'logo'"
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <v-select
                            :value="col.align"
                            outlined
                            dense
                            :items="[
                              { text: 'Vasen', val: 'flex-start' },
                              { text: 'Keskellä', val: 'center' },
                              { text: 'Oikea', val: 'flex-end' },
                            ]"
                            item-value="val"
                            item-text="text"
                            label="Asettelu"
                            hide-details
                            @change="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'align',
                              })
                            "
                          >
                          </v-select
                        ></v-col>

                        <!-- Padding -->
                        <v-col
                          v-if="col.fieldType == 'logo'"
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                          ><v-text-field
                            :value="col.padding"
                            label="Padding"
                            outlined
                            dense
                            hide-details
                            :rules="validations.colPadding"
                            @input="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'padding',
                              })
                            "
                          ></v-text-field
                        ></v-col>

                        <!-- max width -->
                        <v-col
                          v-if="col.fieldType == 'logo'"
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                          ><v-text-field
                            :value="col.maxWidth"
                            label="Max. leveys px"
                            outlined
                            dense
                            hide-details
                            v-only-numbers
                            v-prevent-paste
                            @input="
                              updateCol({
                                val: $event,
                                colIndex,
                                rowIndex,
                                field: 'maxWidth',
                              })
                            "
                          ></v-text-field
                        ></v-col>

                        <!-- Width -->
                        <v-col
                          cols="12"
                          :sm="columnWidthsLeft.sm"
                          :md="columnWidthsLeft.md"
                          :lg="columnWidthsLeft.lg"
                          :xl="columnWidthsLeft.xl"
                        >
                          <custom-cols-input
                            :colIndex="colIndex"
                            :rowIndex="rowIndex"
                          ></custom-cols-input>
                        </v-col>
                      </v-row>

                      <v-divider
                        color="#dfdfdf"
                        style="margin-top: 9px; margin-bottom: 0px"
                      ></v-divider>
                    </v-col>
                  </v-row>
                </draggable>
              </v-col>

              <!-- Add new Row -->
              <v-col v-show="row.showRow" cols="12">
                <v-card-actions style="padding: 0px">
                  <v-icon color="primary" @click="addCol(rowIndex)">mdi-plus</v-icon> Lisää kenttä
                  <v-icon
                    class="ml-2"
                    size="20"
                    color="error"
                    style="cursor: pointer"
                    @click="deleteFullRow(rowIndex)"
                    >mdi-delete</v-icon
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-col>

              <v-icon
                class="handleRow"
                style="cursor: move; position: absolute; bottom: 0; right: 0"
                small
                >mdi-arrow-all</v-icon
              >
            </v-row>
          </draggable>

          <!-- Add new row -->
          <v-row dense :class="rows.length > 0 ? 'mt-2' : ''">
            <v-col cols="12">
              <v-icon color="info" large @click="addRow({ cols: [], showRow: true })"
                >mdi-plus</v-icon
              >
              Lisää rivi
            </v-col>
          </v-row>
        </v-form>

        <!-- Rules -->
        <div style="position: relative">
          <!-- hide show icon -->
          <v-btn icon style="position: absolute; top: 0; right: 0" @click="showRules = !showRules">
            {{ showRules ? "Piilota" : "Näytä" }}
            <v-icon>{{ showRules ? "mdi-chevron-up" : "mdi-chevron-down" }} </v-icon>
          </v-btn>

          <h4 class="mb-1 mt-1">Ehdot</h4>

          <form-rules
            v-show="showRules"
            ref="formRules"
            class="mt-2"
            @resetform="$refs.formPreview.resetForm()"
          ></form-rules>
        </div>
      </v-col>

      <!-- PREVIEW -->
      <v-col
        v-show="formViewOptions.preview.show"
        :cols="formView == '0' ? rightColumnWidth : 12"
        :class="formViewOptions.form.show ? 'pl-1' : ''"
      >
        <form-preview ref="formPreview"></form-preview>
      </v-col>
    </v-row>

    <!-- FORM EMAILS -->
    <form-emails v-if="formView == '3'"></form-emails>

    <!-- Create form -->
    <v-row v-if="rows.length > 0" dense class="mt-2">
      <v-col cols="12"
        ><v-btn color="primary" @click.prevent="submit">{{ submitBtn }}</v-btn></v-col
      >
    </v-row>

    <!-- fieldType dialog -->
    <v-dialog v-model="selectDialog" width="500">
      <v-card>
        <v-card-title>Huom! Lomakkeelle on jo jätetty viestejä</v-card-title>
        <v-card-text>
          <h4 class="error--text">
            Jos muutat kentän tyyppiä, et saa enää tämän kentän tietoja taulukkonäkymään. Näet
            tiedot kuitenkin erillisestä lomakenäkymästä. Kentän alkuperäistä tunnustenimeä ei voi
            myöskään käyttää jatkossa kuin kentän tyypillä "{{ getOldFieldType }}". Hyväksytkö?
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="error" @click="discardChange">Peruuta</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="acceptChange">Hyväksy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pasteJSONDialog" width="500">
      <v-card>
        <v-card-title>Liitä kopioitu lomake</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="JSONText"
            dense
            outlined
            placeholder="Liitä kopioitu lomake leikepöydältä"
            @keydown="preventInput"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="error" @click="pasteJSONDialog = false">Peruuta</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="createTemplate">Luo lomake</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FormPreview from "./FormPreview.vue";
import CustomColsInput from "./CustomCols.vue";
import draggable from "vuedraggable";
import mixins from "@/mixins/mixins";
import validations from "@/validations";
import globalValues from "@/configs/globalValues";
import FormColors from "./FormColors.vue";
import FormRules from "./FormRules.vue";
import { loadFont } from "../../utils/helpers";
import templates from "@/configs/formTemplates.json";
import FormEmails from "./FormEmails.vue";
import { createAndValidateTemplateJs } from "@/utils/formHelpers";

export default {
  mixins: [mixins],

  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    FormPreview,
    CustomColsInput,
    draggable,
    FormColors,
    FormRules,
    FormEmails,
  },

  data() {
    return {
      pasteJSONDialog: false,
      JSONText: "",
      selectDialog: false,
      oldSelectValue: {},
      sliderValue: 6,
      leftColumnWidth: 6,
      rightColumnWidth: 6,
      formView: "0",
      fieldTypes: [
        { text: "Teksti", val: "text" },
        { text: "Numero", val: "number" },
        { text: "Email", val: "email" },
        { text: "Tekstialue", val: "textarea" },
        { text: "Päivämäärä", val: "date" },
        { text: "Checkbox", val: "checkbox" },
        { text: "Radiopainike", val: "radio" },
        { text: "Valinta", val: "select" },
        { text: "Monivalinta", val: "multiselect" },
        { text: "H1", val: "h1" },
        { text: "H2", val: "h2" },
        { text: "H3", val: "h3" },
        { text: "Paragraph", val: "p" },
        { text: "Kuva", val: "image" },
        { text: "Logo", val: "logo" },
        { text: "Painike", val: "button" },
        { text: "Tallenna-nappi", val: "submit" },
      ],
      fieldStyles: [
        { text: "Reunaviiva", val: "outlined" },
        { text: "Ei reunaviivaa", val: "null" },
        { text: "Leijuva", val: "solo" },
      ],
      fonts: globalValues.fonts,
      templates,
      validations,
    };
  },

  computed: {
    ...mapState("form", ["submissionsExists"]),

    submitBtn() {
      return this.edit ? "Tallenna" : "Luo lomake";
    },

    validateEmails() {
      return [
        (v) =>
          !v ||
          v.split(", ").every((email) => validations.isValidEmail(email)) ||
          "Emailit on väärin muotoiltu",
      ];
    },

    formViewOptions() {
      if (this.formView == "0") {
        return {
          form: { show: true },
          preview: { show: true },
        };
      } else if (this.formView == "1") {
        return {
          form: { show: true },
          preview: { show: false },
        };
      } else if (this.formView == "2") {
        return {
          form: { show: false },
          preview: { show: true },
        };
      } else {
        return {
          form: { show: false },
          preview: { show: false },
        };
      }
    },

    columnWidthsLeft() {
      let sm = 6,
        md = 5,
        lg = 3,
        xl = 2;
      if (this.formView == "0") {
        if (this.sliderValue <= 3) {
          sm = 12;
          md = 12;
          lg = 12;
          xl = 12;
        } else if (this.sliderValue == 4) {
          sm = 12;
          md = 12;
          lg = 5;
          xl = 4;
        } else if (this.sliderValue == 5) {
          sm = 8;
          md = 6;
          lg = 4;
          xl = 3;
        } else if (this.sliderValue > 5) {
          sm = 6;
          md = 6;
          lg = 3;
          xl = 2;
        }
      } else {
        return { sm, md, lg, xl };
      }
      return { sm, md, lg, xl };
    },

    uniqueLabels() {
      return (value) => {
        const labels = [];

        // loop through all label inputs to check if the value already exists
        if (this.$refs.label) {
          this.$refs.label.forEach((field) => {
            if (field.value) {
              labels.push(field.value);
            }
          });
        }

        // check if there is more than one occurrence of the label value
        const duplicates = labels.filter((el) => el === value);

        if (duplicates.length > 1) {
          return false;
        } else {
          return true;
        }
      };
    },

    noDots() {
      return (value) => {
        if (value.includes(".")) return false;
        return true;
      };
    },

    unAvailableLabelsDenied() {
      return (obj) => {
        const oldMeta = this.$store.state.form.currentForm.oldMetadata;
        const field = this.$store.state.form.currentForm.rows[obj.rowIndex].cols[obj.colIndex];

        if (!this.submissionsExists) {
          return true;
        }

        // let found = false;
        if (obj.val in oldMeta) {
          if (oldMeta[obj.val].fieldType === field.fieldType) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };
    },

    getOldFieldType() {
      if (this.oldSelectValue) {
        let value = "";
        this.fieldTypes.forEach((el) => {
          if (el.val === this.oldSelectValue.val) {
            value = el.text;
          }
        });
        return value;
      } else {
        return "";
      }
    },

    rows: {
      get() {
        return this.$store.state.form.currentForm.rows;
      },
      set(rows) {
        this.setRows(rows);
      },
    },
    name: {
      get() {
        return this.$store.state.form.currentForm.name;
      },
      set(val) {
        this.setField({ field: "name", val });
      },
    },
    recipients: {
      get() {
        return this.$store.state.form.currentForm.recipients;
      },
      set(val) {
        this.setField({ field: "recipients", val });
      },
    },
    align: {
      get() {
        return this.$store.state.form.currentForm.styles.align;
      },
      set(val) {
        this.setField({ field: "styles.align", val });
      },
    },
    fontFamily: {
      get() {
        return this.$store.state.form.currentForm.styles.fontFamily;
      },
      set(val) {
        this.updateFont(val);
        this.setField({ field: "styles.fontFamily", val });
      },
    },
    width: {
      get() {
        return this.$store.state.form.currentForm.styles.width;
      },
      set(val) {
        this.setField({ field: "styles.width", val });
      },
    },
    formPadding: {
      get() {
        return this.$store.state.form.currentForm.styles.formPadding;
      },
      set(val) {
        this.setField({ field: "styles.formPadding", val });
      },
    },
    border: {
      get() {
        return this.$store.state.form.currentForm.styles.border;
      },
      set(val) {
        this.setField({ field: "styles.border", val });
      },
    },
    showError: {
      get() {
        return this.$store.state.form.currentForm.styles.showError;
      },
      set(val) {
        this.setField({ field: "styles.showError", val });
      },
    },
    showNameAbove: {
      get() {
        return this.$store.state.form.currentForm.styles.showNameAbove;
      },
      set(val) {
        this.setField({ field: "styles.showNameAbove", val });
      },
    },
    showRules: {
      get() {
        return this.$store.state.form.currentForm.showRules;
      },
      set(val) {
        this.setField({ field: "showRules", val });
      },
    },
  },

  methods: {
    ...mapActions("form", ["createForm", "editForm"]),
    ...mapMutations("form", [
      "addRow",
      "addCol",
      "setField",
      "setColor",
      "updateCol",
      "removeRuleOptions",
      "setRows",
      "setCols",
      "deleteRow",
      "hideOrShowRow",
      "setCurrentForm",
      "setDefaultTemplate",
      "renameRules",
      "removeUnnecessaryRules",
    ]),

    async submit() {
      if (
        this.$refs.form_options.validate() &&
        this.$refs.form_body.validate() &&
        this.$refs.formRules.validateRules()
      ) {
        if (!this.errorChecking()) {
          return;
        }
        // Edit or create
        try {
          if (this.edit) {
            await this.editForm(this.$route.params.id);
          } else {
            await this.createForm();
          }
        } catch (err) {
          return this.showPopup(err, "error");
        }

        this.$router.push("/forms");
      } else {
        this.showPopup("Kentissä on puutteita. Tarkista lomake.", "error");
      }
    },

    errorChecking() {
      let includesRequiredField = false;
      let emptyCols = false;
      let submitFound = false;

      for (const row of this.rows) {
        if (row.cols.length == 0) {
          emptyCols = true;
          break;
        }
        for (const col of row.cols) {
          if (col.fieldType == "submit") submitFound = true;
          if (col.required) includesRequiredField = true;
        }
      }

      if (emptyCols) {
        this.showPopup(
          "Lomake sisältää tyhjiä rivejä. Lisää jokaiselle riville ainakin yksi kenttä.",
          "error"
        );
        return false;
      }
      if (!includesRequiredField) {
        this.showPopup("Lomakkeella on oltava ainakin yksi pakollinen kenttä", "error");
        return false;
      }

      if (!submitFound) {
        this.showPopup("Lomakkeella on oltava Tallenna-nappi", "error");
        return false;
      }
      return true;
    },

    checkSubmissions(obj) {
      // create mode, no submissions, not label field or new field
      if (!this.edit || !this.submissionsExists || !obj.labelExists || !obj.oldFieldType) {
        this.updateCol(obj);
      } else {
        this.oldSelectValue = {
          rowIndex: obj.rowIndex,
          colIndex: obj.colIndex,
          val: this.$store.state.form.currentForm.rows[obj.rowIndex].cols[obj.colIndex].fieldType,
          type: this.$store.state.form.currentForm.rows[obj.rowIndex].cols[obj.colIndex].type,
        };
        // Update first
        this.updateCol(obj);
        // In the dialog, return saved vale if user selects false
        if (
          obj.oldFieldType !=
          this.$store.state.form.currentForm.rows[obj.rowIndex].cols[obj.colIndex].fieldType
        )
          this.selectDialog = true;
      }
    },

    acceptChange() {
      this.updateCol({
        val: "",
        colIndex: this.oldSelectValue.colIndex,
        rowIndex: this.oldSelectValue.rowIndex,
        field: "label",
        type: this.$store.state.form.currentForm.rows[this.oldSelectValue.rowIndex].cols[
          this.oldSelectValue.colIndex
        ].type,
        fieldType:
          this.$store.state.form.currentForm.rows[this.oldSelectValue.rowIndex].cols[
            this.oldSelectValue.colIndex
          ].fieldType,
      });

      this.selectDialog = false;
    },

    discardChange() {
      const obj = {
        val: this.oldSelectValue.val,
        field: "fieldType",
        rowIndex: this.oldSelectValue.rowIndex,
        colIndex: this.oldSelectValue.colIndex,
      };

      this.updateCol(obj);
      this.selectDialog = false;
    },

    updateCols(rowIndex, event) {
      this.setCols({
        rowIndex,
        oldIndex: event.oldIndex,
        newIndex: event.newIndex,
      });
    },

    deleteFullRow(index) {
      if (confirm("Haluatko poistaa koko rivin?")) {
        this.deleteRow(index);
      }
    },

    updateColumnWidths() {
      this.leftColumnWidth = this.sliderValue;
      this.rightColumnWidth = 12 - this.sliderValue;
    },

    getLabels(cols) {
      const maxLabels = 6;
      let labelString = "";
      let labelsAdded = 0;

      for (let i = 0; i < cols.length; i++) {
        if (cols[i].label && cols[i].label !== "") {
          labelsAdded++;
          labelString += cols[i].label;

          if (labelsAdded < maxLabels && i < cols.length - 1) {
            labelString += ", ";
          } else if (labelsAdded === maxLabels && cols.length > maxLabels) {
            labelString += "...";
            break;
          }
        }

        if (labelsAdded >= maxLabels) {
          break;
        }
      }

      if (labelString.endsWith(", ")) {
        labelString = labelString.slice(0, -2);
      }

      return labelString;
    },

    updateFont(font) {
      loadFont(font);
    },

    loadTemplate(template) {
      if (template === "Liitä kopioitu lomake") {
        this.pasteJSONDialog = true;
      } else {
        const js = JSON.parse(JSON.stringify(this.templates));
        this.setDefaultTemplate(js[template]);
      }
    },

    createTemplate() {
      try {
        const js = createAndValidateTemplateJs(this.JSONText);
        this.setDefaultTemplate(js);
        this.JSONText = "";
        this.pasteJSONDialog = false;
        this.showPopup("Uusi lomake kopioitu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    preventInput(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "v") {
        return;
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.row-container {
  position: relative;
  border: 2px solid rgb(201, 201, 201);
  padding: 5px;
  border-radius: 4px;
  min-height: 45px;
}

.row-container-2 {
  position: relative;
}
</style>
