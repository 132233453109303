var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Reunaväri","outlined":"","dense":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"100","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.borderStyle)},on))]}}]),model:{value:(_vm.borderColorMenu),callback:function ($$v) {_vm.borderColorMenu=$$v},expression:"borderColorMenu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":"","mode":"hexa","hide-mode-switch":""},model:{value:(_vm.borderColor),callback:function ($$v) {_vm.borderColor=$$v},expression:"borderColor"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.borderColor),callback:function ($$v) {_vm.borderColor=$$v},expression:"borderColor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Kentän väri","outlined":"","dense":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"100","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.fieldStyle)},on))]}}]),model:{value:(_vm.fieldColorMenu),callback:function ($$v) {_vm.fieldColorMenu=$$v},expression:"fieldColorMenu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":"","mode":"hexa","hide-mode-switch":""},model:{value:(_vm.fieldColor),callback:function ($$v) {_vm.fieldColor=$$v},expression:"fieldColor"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.fieldColor),callback:function ($$v) {_vm.fieldColor=$$v},expression:"fieldColor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Tekstin väri","outlined":"","dense":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"100","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.textStyle)},on))]}}]),model:{value:(_vm.textColorMenu),callback:function ($$v) {_vm.textColorMenu=$$v},expression:"textColorMenu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":"","mode":"hexa","hide-mode-switch":""},model:{value:(_vm.textColor),callback:function ($$v) {_vm.textColor=$$v},expression:"textColor"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.textColor),callback:function ($$v) {_vm.textColor=$$v},expression:"textColor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":"Error väri","outlined":"","dense":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"100","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.errorStyle)},on))]}}]),model:{value:(_vm.errorColorMenu),callback:function ($$v) {_vm.errorColorMenu=$$v},expression:"errorColorMenu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":"","mode":"hexa","hide-mode-switch":""},model:{value:(_vm.errorColor),callback:function ($$v) {_vm.errorColor=$$v},expression:"errorColor"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.errorColor),callback:function ($$v) {_vm.errorColor=$$v},expression:"errorColor"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }