<template>
  <div>
    <v-row dense>
      <!-- Checkbox -->
      <v-checkbox
        v-model="showGrid"
        label="Näytä palstat"
        style="margin-top: 0px; padding-top: 0px"
        dense
      />
      <v-btn class="ml-2 mb-1" @click="$refs.form.validateForm()" small color="primary"
        >Validoi</v-btn
      >
      <v-btn class="ml-2 mb-1" @click="$refs.form.resetFormValidation()" small color="warning"
        >Tyhjennä validointi</v-btn
      >
      <v-btn class="ml-2 mb-1" @click="resetForm" small color="primary">Tyhjennä kentät</v-btn>
    </v-row>

    <!-- Form body -->
    <form-body :isPreview="true" :showGrid="showGrid" ref="form"></form-body>
  </div>
</template>

<script>
import validations from "../../validations";
import FormBody from "./FormBody.vue";

export default {
  data() {
    return {
      showGrid: false,
      validations,
    };
  },

  components: {
    FormBody,
  },

  methods: {
    resetForm() {
      this.$refs.form.resetForm();
    },
  },
};
</script>

<style scoped></style>
