<template>
  <div>
    <strong>Esimerkit classien käytöstä</strong>
    <p>.small-margin { margin-bottom: 5px; margin-top: 0px; }</p>
    <p>.big-margin { margin-bottom: 15px; margin-top: 15px; }</p>
    <h4 class="mt-1 mb-1">Sähköpostikentät</h4>
    <v-row dense>
      <v-col cols="12" sm="6" md="5" lg="3" xl="2">
        <v-select
          outlined
          dense
          :items="ruleFields"
          item-text="label"
          item-value="label"
          label="Kenttä"
          hide-details
          @change="addToText"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="7" lg="4">
        <v-textarea
          v-model="emailStr"
          outlined
          dense
          label="Viesti"
          :placeholder="`Voit lisätä h1, h2, h3, p ja strong elementtejä...`"
          rows="20"
        ></v-textarea>
        <v-btn color="info" small @click="emailStr = ''">Tyhjennä viesti</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      acceptedRuleFieldTypes: [
        "text",
        "number",
        "email",
        "textarea",
        "date",
        "checkbox",
        "radio",
        "select",
        "multiselect",
      ],
    };
  },

  computed: {
    emailStr: {
      get() {
        return this.$store.state.form.currentForm.emailStr;
      },
      set(val) {
        this.setEmailStr(val);
      },
    },

    rows() {
      return this.$store.state.form.currentForm.rows;
    },

    ruleFields() {
      if (this.rows) {
        const fields = [];

        this.rows.forEach((row) => {
          row.cols.forEach((col) => {
            if (this.acceptedRuleFieldTypes.includes(col.fieldType)) fields.push(col);
          });
        });
        return fields;
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapMutations("form", ["setEmailStr", "setEmailVariable"]),

    addToText(val) {
      this.setEmailVariable(`<p class="small-margin">{{${val}}}</p>\n`);
    },
  },
};
</script>

<style></style>
