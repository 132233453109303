<template>
  <v-col cols="12" sm="6" md="4" lg="3">
    <v-row dense>
      <!-- Border -->
      <v-col cols="6">
        <v-text-field
          v-model="borderColor"
          class="ma-0 pa-0"
          label="Reunaväri"
          outlined
          dense
          hide-details
          readonly
        >
          <template v-slot:append>
            <v-menu
              v-model="borderColorMenu"
              top
              nudge-bottom="100"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div :style="borderStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="borderColor" flat mode="hexa" hide-mode-switch />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>

      <!-- Field -->
      <v-col cols="6">
        <v-text-field
          v-model="fieldColor"
          class="ma-0 pa-0"
          label="Kentän väri"
          outlined
          dense
          hide-details
          readonly
        >
          <template v-slot:append>
            <v-menu
              v-model="fieldColorMenu"
              top
              nudge-bottom="100"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div :style="fieldStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="fieldColor" flat mode="hexa" hide-mode-switch />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>

      <!-- Text -->
      <v-col cols="6">
        <v-text-field
          v-model="textColor"
          class="ma-0 pa-0"
          label="Tekstin väri"
          outlined
          dense
          hide-details
          readonly
        >
          <template v-slot:append>
            <v-menu
              v-model="textColorMenu"
              top
              nudge-bottom="100"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div :style="textStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="textColor" flat mode="hexa" hide-mode-switch />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>

      <!-- ERror -->
      <v-col cols="6">
        <v-text-field
          v-model="errorColor"
          class="ma-0 pa-0"
          label="Error väri"
          outlined
          dense
          hide-details
          readonly
        >
          <template v-slot:append>
            <v-menu
              v-model="errorColorMenu"
              top
              nudge-bottom="100"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div :style="errorStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="errorColor" flat mode="hexa" hide-mode-switch />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      borderColorMenu: false,
      fieldColorMenu: false,
      textColorMenu: false,
      errorColorMenu: false,
    };
  },

  computed: {
    borderStyle() {
      const { borderColor, borderColorMenu } = this;
      return {
        backgroundColor: borderColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: borderColorMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    fieldStyle() {
      const { fieldColor, fieldColorMenu } = this;
      return {
        backgroundColor: fieldColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: fieldColorMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    textStyle() {
      const { textColor, textColorMenu } = this;
      return {
        backgroundColor: textColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: textColorMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    errorStyle() {
      const { errorColor, errorColorMenu } = this;
      return {
        backgroundColor: errorColor,
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: errorColorMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    borderColor: {
      get() {
        return this.$store.state.form.currentForm.styles.colors.borderColor;
      },
      set(val) {
        this.setField({ field: "styles.colors.borderColor", val });
      },
    },
    fieldColor: {
      get() {
        return this.$store.state.form.currentForm.styles.colors.fieldColor;
      },
      set(val) {
        this.setField({ field: "styles.colors.fieldColor", val });
      },
    },
    textColor: {
      get() {
        return this.$store.state.form.currentForm.styles.colors.textColor;
      },
      set(val) {
        this.setField({ field: "styles.colors.textColor", val });
      },
    },
    errorColor: {
      get() {
        return this.$store.state.form.currentForm.styles.colors.errorColor;
      },
      set(val) {
        this.setField({ field: "styles.colors.errorColor", val });
      },
    },
  },

  methods: {
    ...mapMutations("form", ["setField"]),
  },
};
</script>

<style></style>
