<template>
  <div v-if="currentForm.rules">
    <v-form ref="form">
      <v-row v-for="(rule, i) in currentForm.rules" :key="'a' + i" dense>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="12" sm="6" md="5" lg="3" xl="2">
              <v-select
                v-model="rule.ruleField"
                outlined
                dense
                :items="ruleFields"
                item-text="label"
                item-value="label"
                label="Kenttä"
                hide-details
                :rules="validations.required"
                @change="
                  filterTargetFields(i);
                  emptyRuleFieldValues(i);
                  $emit('resetform');
                "
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="3" xl="2">
              <v-select
                v-model="rule.ruleFieldBoolean"
                :items="ruleFieldBooleans"
                outlined
                dense
                item-text="text"
                item-value="val"
                label="Kentän arvo"
                hide-details
                :rules="validations.requiredBooleanVal"
              ></v-select>
            </v-col>
            <v-col v-if="rule.ruleField && showRuleFieldValues(rule.ruleField)" cols="12" sm="6">
              <v-select
                v-model="rule.ruleFieldValues"
                :items="getOptions(rule.ruleField)"
                multiple
                small-chips
                deletable-chips
                outlined
                dense
                item-text="text"
                item-value="val"
                label="Kentän valinnat"
                hide-details
                :rules="validations.requiredArray"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="rule.targetFields"
                :items="targetFields(i)"
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                item-text="label"
                item-value="label"
                label="Kohdekentät"
                hide-details
                :rules="validations.requiredArray"
                @change="filterTargetFields(i)"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="3" xl="2">
              <v-select
                v-model="rule.rule"
                :items="acceptedRules"
                outlined
                dense
                item-text="text"
                item-value="val"
                label="Sääntö"
                hide-details
                :rules="validations.required"
              >
                <template slot="append-outer">
                  <v-icon
                    color="error"
                    style="cursor: pointer; margin-top: 5px"
                    size="15"
                    @click="removeRule(i)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-divider class="mt-1"></v-divider>
        </v-col>
      </v-row>
    </v-form>
    <v-btn small @click="addNewRule" color="primary" class="mt-1">Lisää ehto</v-btn>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import validations from "../../validations";

export default {
  data() {
    return {
      acceptedRuleFieldTypes: [
        "text",
        "number",
        "email",
        "textarea",
        "date",
        "checkbox",
        "radio",
        "select",
        "multiselect",
      ],
      acceptedTargetFieldTypes: [
        "text",
        "number",
        "email",
        "textarea",
        "date",
        "checkbox",
        "radio",
        "select",
        "multiselect",
        // "h1",
        // "h2",
        // "h3",
        // "p",
      ],
      acceptedRuleValueFieldTypes: ["radio", "select", "multiselect"],
      acceptedRules: [
        { text: "Näytä", val: "show" },
        { text: "Piilota", val: "hide" },
      ],
      ruleFieldBooleans: [
        { text: "Kyllä/Täytetty", val: true },
        { text: "Ei/Ei täytetty", val: false },
      ],
      validations,
    };
  },

  computed: {
    ...mapState("form", ["currentForm"]),

    rows() {
      return this.$store.state.form.currentForm.rows;
    },

    ruleFields() {
      if (this.rows) {
        const fields = [];

        this.rows.forEach((row) => {
          row.cols.forEach((col) => {
            if (this.acceptedRuleFieldTypes.includes(col.fieldType)) fields.push(col);
          });
        });
        return fields;
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapMutations("form", [
      "addRule",
      "removeRule",
      "filterTargetFields",
      "emptyRuleFieldValues",
    ]),

    validateRules() {
      return this.$refs.form.validate();
    },

    addNewRule() {
      this.addRule();
      this.$forceUpdate();
    },

    getField(label) {
      let field = null;
      this.rows.forEach((row) => {
        row.cols.forEach((col) => {
          if (col.label === label) field = col;
        });
      });
      return field;
    },

    showRuleFieldValues(label) {
      const field = this.getField(label);

      if (field) {
        if (this.acceptedRuleValueFieldTypes.includes(field.fieldType)) return true;
        return false;
      } else {
        return false;
      }
    },

    targetFields(ruleRow) {
      if (this.rows) {
        const fields = [];

        this.rows.forEach((row) => {
          row.cols.forEach((col) => {
            if (
              this.acceptedTargetFieldTypes.includes(col.fieldType) &&
              this.$store.state.form.currentForm.rules[ruleRow].ruleField != col.label
            )
              fields.push(col);
          });
        });
        return fields;
      } else {
        return [];
      }
    },

    getOptions(label) {
      const field = this.getField(label);
      return field.options.split(", ");
    },
  },
};
</script>

<style></style>
